@import '@/assets/styles/global';

.Tooltip {

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    padding: 0.2rem 0.4rem 0.4rem;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: 0.015em;
    min-height: 2.6rem;
    background: $grayscale-800;
    box-shadow: 0px 0.2rem 0.4rem rgba(38, 50, 56, 0.15);
    white-space: pre-wrap;
  }
}
