@import '@/assets/styles/global';

.Input {
  &.show-only-text {
    .ant-input-affix-wrapper {
      border: none !important;
      box-shadow: none !important;
      background: transparent !important;

      .ant-input {
        padding: 0 !important;
        box-shadow: none !important;
      }
    }
  }

  .ant-input-affix-wrapper {
    background: $white;
    border-radius: .4rem;
    border-color: $grayscale-200;
    padding: 0;

    &:focus,
    &.ant-input-affix-wrapper-focused,
    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: $grayscale-900;
    }

    &.ant-input-affix-wrapper-disabled {
      border-color: $grayscale-100;
      background: $grayscale-50;

      .ant-input {
        color: $grayscale-600;
      }
    }

    &.ant-input-affix-wrapper-sm {
      .ant-input {
        height: 3.6rem;
        padding: .4rem 1.2rem;
        font-size: 1.4rem;
      }
    }

    .ant-input {
      padding: .8rem 1.2rem;
      height: 4.4rem;
      font-size: 1.6rem;
      line-height: 2.8rem;
      background: transparent;
      color: $grayscale-900;

      &::placeholder {
        color: $grayscale-400;
      }
    }
  }

  &-suffix {
    padding-left: .8rem;
    border-left: 1px solid $grayscale-100;
  }

  .ant-input-prefix {
    padding: 0 0rem 0 1.2rem;
    margin-right: 0;
  }

  .ant-input-suffix {
    padding: 0 1.2rem 0 0.8rem;
    margin-left: 0;
  }

  .ant-input-prefix,
  .ant-input-suffix {
    .Icon {
      width: 2rem;
      height: 2rem;
    }
  }

  &.no-affix-border {
    .Input {

      &-suffix,
      &-prefix {
        border: none;
      }
    }
  }
}
