// Extension File Format:
// - .otf: opentype
// - .ttf: truetype
// - .woff: woff
// - .woff2: woff2

@font-face {
  font-family: Sarabun;
  font-weight: 800;
  src: url('../fonts/Sarabun/Sarabun-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: Sarabun;
  font-weight: 700;
  src: url('../fonts/Sarabun/Sarabun-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Sarabun;
  font-weight: 600;
  src: url('../fonts/Sarabun/Sarabun-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Sarabun;
  font-weight: 500;
  src: url('../fonts/Sarabun/Sarabun-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Sarabun;
  font-weight: 400;
  src: url('../fonts/Sarabun/Sarabun-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Sarabun;
  font-weight: 300;
  src: url('../fonts/Sarabun/Sarabun-Light.ttf') format('truetype');
}

@font-face {
  font-family: Sarabun;
  font-weight: 200;
  src: url('../fonts/Sarabun/Sarabun-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: Sarabun;
  font-weight: 100;
  src: url('../fonts/Sarabun/Sarabun-Thin.ttf') format('truetype');
}