@import '@/assets/styles/_global.scss';

.UploadDragDrop {
  .ant-upload-drag-hover {
    .ant-upload {
      background: $blue-100 !important;
    }
  }

  .ant-upload {
    background: $white;
    border-width: 2px;
    border-radius: .4rem;
    border-color: $blue-500;
    transition: $common-transition;

    &:hover {
      background: $blue-100 !important;
    }

    .ant-upload-btn {
      padding: 3.5rem 0;
    }
  }

  &-icon {
    margin: 0 auto .8rem;
    width: fit-content;

    .Icon {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &-text {
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: center;
    color: $grayscale-900;

    span {
      font-weight: 600;
      color: $blue-500;
    }
  }

  &-preview {
    &-item {
      column-gap: .8rem;
      cursor: pointer;

      span {
        flex: 1;
        font-size: 1.4rem;
        font-weight: 600;
        color: $blue-500;
        @include ellipsis(1);
      }

      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }

      .Icon {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
}
