@import '@/assets/styles/global';

.Button {
  @mixin SizeIcon($size) {
    flex: 0 0 $size;
    max-width: $size;
    width: $size;
    height: $size;
  }

  @mixin ColorIcon($color, $isImportant: false) {
    .Icon {
      .fill {
        @if ($isImportant) {
          fill: $color !important;
        }

        @else {
          fill: $color;
        }
      }

      .stroke {
        @if ($isImportant) {
          stroke: $color !important;
        }

        @else {
          stroke: $color;
        }
      }
    }
  }

  &.no-padding {
    .ant-btn {
      padding: 0 !important;
    }
  }

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .2rem;
    height: 3.6rem;
    padding: .6rem;
    column-gap: .6rem;
    width: 100%;
    transition: $common-transition;

    .fill,
    .stroke {
      transition: $common-transition;
    }

    .Button-title {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 2.4rem;
    }

    .Icon {
      @include SizeIcon(1.6rem);
    }

    &.ant-btn-lg {
      height: 4.4rem;
      padding: .8rem;
      column-gap: .8rem;
      border-radius: .4rem;

      .Button-title {
        font-size: 1.6rem;
        line-height: 2.8rem;
      }

      .Icon {
        @include SizeIcon(2rem);
      }
    }

    &.ant-btn-sm {
      height: 2.8rem;
      padding: .4rem;
      column-gap: .4rem;

      .Button-title {
        font-size: 1.4rem;
        line-height: 1;
      }

      .Icon {
        @include SizeIcon(1.2rem);
      }
    }
  }

  &.reverse {
    .ant-btn {
      flex-direction: row-reverse;
    }
  }

  &.PRIMARY {
    .ant-btn {
      border: none;
      background: $blue-500;
      color: $white;
      @include ColorIcon($white);

      &:hover {
        background: $blue-600;
      }

      &:active {
        background: $blue-700;
      }

      &:disabled {
        background: $grayscale-100 !important;
        color: $white !important;
      }
    }
  }

  &.SECONDARY {
    &.no-hover-style {
      .ant-btn {
        &:hover {
          background: $white;
        }
      }
    }

    .ant-btn {
      border: none;
      background: $white;
      color: $grayscale-900;
      @include ColorIcon($grayscale-900);

      &:hover {
        background: $black-05;
      }

      &:active {
        background: $black-12;
      }

      &:disabled {
        background: transparent !important;
        color: $grayscale-200 !important;
        @include ColorIcon($grayscale-200, true);
      }
    }
  }

  &.OUTLINE_PRIMARY {
    .ant-btn {
      background: $white;
      border-color: $blue-500;
      color: $blue-500;
      @include ColorIcon($blue-500);

      &:hover {
        background: $blue-100;
      }

      &:active {
        background: $blue-200;
        color: $blue-700;
        border-color: $blue-700;
        @include ColorIcon($blue-700);
      }

      &:disabled {
        background: $grayscale-50 !important;
        border-color: $grayscale-100 !important;
        color: $grayscale-200 !important;
        @include ColorIcon($grayscale-200, true);
      }
    }
  }

  &.TEXT_PRIMARY {
    .ant-btn {
      background: transparent;
      border: none;
      box-shadow: none;
      color: $blue-500;
      @include ColorIcon($blue-500);

      &:hover,
      &:active {
        background: $blue-100;
        color: $blue-700;
        @include ColorIcon($blue-700);
      }

      &:disabled {
        color: $grayscale-200;
        @include ColorIcon($grayscale-200);
      }
    }
  }

  &.TEXT_SUCCESS {
    .ant-btn {
      background: transparent;
      border: none;
      box-shadow: none;
      color: $lime-500;
      @include ColorIcon($lime-500);

      &:hover,
      &:active {
        background: $lime-100;
        color: $lime-700;
        @include ColorIcon($lime-700);
      }

      &:disabled {
        color: $grayscale-200;
        @include ColorIcon($grayscale-200);
      }
    }
  }

  &.TEXT_DANGER {
    .ant-btn {
      background: transparent;
      border: none;
      box-shadow: none;
      color: $red-500;
      @include ColorIcon($red-500);

      &:hover,
      &:active {
        background: $red-100;
        color: $red-700;
        @include ColorIcon($red-700);
      }

      &:disabled {
        color: $grayscale-200;
        @include ColorIcon($grayscale-200);
      }
    }
  }

  &.TEXT_BLACK {
    &.active {
      .ant-btn {
        background: $black-12;
        @include ColorIcon($grayscale-900);
      }
    }

    .ant-btn {
      background: transparent;
      box-shadow: none;
      border: none;
      color: $grayscale-900;
      @include ColorIcon($grayscale-900);

      &:hover,
      &:active {
        background: $black-12;
        @include ColorIcon($grayscale-900);
      }

      &:disabled {
        color: $grayscale-200;
        @include ColorIcon($grayscale-200);
      }
    }
  }

  &.DANGER {
    &.active {
      .ant-btn {
        background: $red-500;
        @include ColorIcon($white);
      }
    }

    .ant-btn {
      background: $red-500;
      box-shadow: none;
      border: none;
      color: white;
      @include ColorIcon(white);

      &:hover,
      &:active {
        background: $red-500;
        @include ColorIcon(white);
      }

      &:disabled {
        color: $grayscale-200;
        @include ColorIcon($grayscale-200);
      }
    }
  }

  &.active.only-icon {
    .ant-btn {
      background: $black-05;
    }
  }

  &.only-icon {
    .ant-btn {
      width: 3.6rem;
      height: 3.6rem;
      background: transparent;
      border: none;

      &:hover {
        background: $black-03;
      }

      &:active {
        background: $black-05;
      }

      &:disabled {
        background: transparent !important;
        @include ColorIcon($grayscale-200, true);
      }

      &.ant-btn-sm {
        width: 2.4rem;
        height: 2.4rem;

      }

      &.ant-btn-lg {
        width: 4.4rem;
        height: 4.4rem;
      }
    }
  }

  &-badge {
    position: absolute;
    top: -0.4rem;
    right: -1rem;
    font-size: 1.2rem;
    line-height: 2rem;
    color: $white;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: $red-500;
    pointer-events: none;
  }
}
