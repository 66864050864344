/*
- Please follow this tool to name your color, then please define the color in src/common-types/ui.ts also
  https://chir.ag/projects/name-that-color/#6195ED

- If colors same name, please add suffix. Ex: persian-blue, persian-blue-dark, persian-blue-light, ...
*/

$mercury: #e6e6e6;
$pelorous: #36B4BC;
$remy: #FDDFF8;
$medium-red-violet: #A62E93;

$grayscale-900: #263238;
$grayscale-800: #37474F;
$grayscale-700: #455A64;
$grayscale-600: #546E7A;
$grayscale-500: #607D8B;
$grayscale-400: #78909C;
$grayscale-300: #90A4AE;
$grayscale-200: #B0BEC5;
$grayscale-100: #CFD8DC;
$grayscale-50: #ECEFF1;

$white: #ffffff;
$white-85: rgba($white, 0.85);
$white-70: rgba($white, 0.70);
$white-50: rgba($white, 0.50);
$white-25: rgba($white, 0.25);
$white-12: rgba($white, 0.12);
$white-08: rgba($white, 0.08);
$white-05: rgba($white, 0.05);

$black: #000000;
$black-85: rgba($black, 0.85);
$black-70: rgba($black, 0.70);
$black-50: rgba($black, 0.50);
$black-25: rgba($black, 0.25);
$black-12: rgba($black, 0.12);
$black-08: rgba($black, 0.08);
$black-05: rgba($black, 0.05);
$black-03: rgba($black, 0.03);

$yellow-900: #79430A;
$yellow-800: #935811;
$yellow-700: #B6751C;
$yellow-600: #DA9628;
$yellow-500: #FEB938;
$yellow-400: #FECF69;
$yellow-300: #FEDD87;
$yellow-200: #FEEBAF;
$yellow-100: #FEF6D7;

$teal-900: #004858;
$teal-800: #00616A;
$teal-700: #008483;
$teal-600: #009E8E;
$teal-500: #00B894;
$teal-400: #35D4A6;
$teal-300: #5DE9B3;
$teal-200: #94F7C8;
$teal-100: #C9FBDE;

$lime-900: #0A5A17;
$lime-800: #116D18;
$lime-700: #1B871B;
$lime-600: #30A127;
$lime-500: #4ABC36;
$lime-400: #7ED663;
$lime-300: #A5EA86;
$lime-200: #CCF8B1;
$lime-100: #E8FBD7;

$blue-900: #01266C;
$blue-800: #023683;
$blue-700: #044CA3;
$blue-600: #0666C3;
$blue-500: #0984E3;
$blue-400: #42ACEE;
$blue-300: #68C8F6;
$blue-200: #9BE2FC;
$blue-100: #E4F8FD;

$violet-900: #17116E;
$violet-800: #251D85;
$violet-700: #392EA6;
$violet-600: #5043C6;
$violet-500: #6C5CE7;
$violet-400: #9184F0;
$violet-300: #AA9EF7;
$violet-200: #C8BFFC;
$violet-100: #E4DFFD;

$red-900: #66092A;
$red-800: #7C0F2C;
$red-700: #9A182F;
$red-600: #B82330;
$red-500: #D63031;
$red-400: #E66B60;
$red-300: #F29481;
$red-200: #FAC0AD;
$red-100: #FCE3D5;