@import '@/assets/styles/_global.scss';

.QuickActionsDropdown {
  padding: 2.4rem;
  max-height: 70vh;
  overflow: auto;

  &-title {
    font-weight: 600;
    color: $grayscale-400;
    margin-bottom: 0.4rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  &-actions {
    &-item {
      column-gap: .8rem;
      cursor: pointer;
      padding: 1rem 1.2rem;
      transition: $common-transition;
      border-radius: .4rem;

      &:not(:last-child) {
        margin-bottom: 0.4rem;
      }

      &:hover {
        background: $black-03;
      }

      &-icon {
        width: 2.4rem;
        height: 2.4rem;
        @include centerOriginImage;

        .Icon {
          width: 100%;
          height: 100%;
        }
      }

      &-title {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: $grayscale-900;
        white-space: nowrap;
      }
    }
  }
}
