@import '@/assets/styles/global';

.WrapperLazyLoad {
  overflow: hidden;

  &.show-scroll {
    transition: $common-transition;
    @include styleScrollBar;

    &:hover {
      overflow: auto;
      padding-right: .8rem;
    }
  }

  .rc-virtual-list-holder {
    max-height: 100% !important;
  }

  &-loading {
    display: none;

    &.show {
      display: block;
    }
  }
}
