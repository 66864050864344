@import '@/assets/styles/_global.scss';

.InputActionUsers {
  &-dropdown {
    .Empty {
      padding: 2.4rem 0;
    }
  }

  &-list {
    &-item {
      cursor: pointer;
      transition: $common-transition;
      column-gap: 1.2rem;
      padding: .8rem 1.2rem;

      &:hover {
        background: $blue-100;
      }

      &-info {
        &-description {
          font-size: 1.2rem;
          line-height: 2rem;
          color: $grayscale-600;
        }

        &-title {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.8rem;
          color: $grayscale-900;
        }
      }
    }
  }
}
