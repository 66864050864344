@import '@/assets/styles/_global.scss';

.AccountDropdown {
  min-width: 30rem;

  &-account {
    column-gap: 1.6rem;
    padding: 1.2rem;
    margin-bottom: 0.4rem;

    &-info {
      &-title {
        font-size: 1.6rem;
        font-weight: 600;
        color: $grayscale-900;
      }

      &-description {
        color: $grayscale-600;
        font-size: 1.2rem;
      }
    }
  }

  &-list {

    &-item {
      column-gap: .8rem;
      padding: 1rem 1.2rem;
      cursor: pointer;
      transition: $common-transition;
      user-select: none;

      &.border {
        border-top: 1px solid $grayscale-50;
      }

      &.danger {
        .AccountDropdown-list-item {
          &-title {
            color: $red-500;
          }
        }
      }

      &:hover {
        background: $black-05;
      }

      &:not(:last-child) {
        margin-bottom: 0.4rem;
      }

      &-icon {
        width: 2.4rem;
        height: 2.4rem;

        &:last-child {
          margin-left: auto;
        }
      }

      &-title {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: $grayscale-900;
      }
    }
  }
}
