@import '@/assets/styles/global';

.ant-modal-mask {
  background: $black-50;
}

.Modal {
  .ant-modal-content {
    border-radius: .4rem;
    box-shadow: 0px 4px 4px rgba(66, 66, 66, 0.24), 0px 0px 1px rgba(66, 66, 66, 0.28);

    .ant-modal-body {
      padding: 0;
    }
  }

  &-header {
    padding: 2rem 2rem 1.2rem;
  }

  &-body {
    padding: 2rem;
  }

  &-title {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.8rem;
    color: $grayscale-900;
  }

  &-close {
    position: absolute;
    cursor: pointer;
    user-select: none;
    width: 2.4rem;
    height: 2.4rem;
    top: 1.2rem;
    right: 1.2rem;
  }

  &-footer {
    border-top: 1px solid $grayscale-50;
    padding: 1.6rem 2rem 2rem;

    .Button {
      min-width: 6rem;
    }
  }
}
