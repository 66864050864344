.title-1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.title-4 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
}

.title-5 {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.subtitle-1 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.subtitle-2 {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.017rem;
}

.body-1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: -0.003rem;
}

.body-2 {
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 400;
  letter-spacing: 0.017rem;
}

.footnote {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.017rem;
}