@import '@/assets/styles/_global.scss';

.FormLabel {
  font-size: 1.4rem;
  font-weight: 500;
  color: $grayscale-600;
  margin-bottom: 0.4rem;
  column-gap: .4rem;
  min-height: 2.2rem;

  &.disabled {
    color: $grayscale-200;

    span {
      color: $grayscale-200;
    }
  }

  span {
    color: $red-500;
    font-size: 1.2rem;
    font-weight: 600;
    transform: translate(0%, -10%);
    display: inline-block;
  }

  .Icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}
