@import '@/assets/styles/_global.scss';

.TextAreaEmoji {
  &-wrapper {
    cursor: pointer;

    .Icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .EmojiPickerReact {
    border: none !important;
  }
}
