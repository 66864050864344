@import '~antd/dist/antd.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-medium-image-zoom/dist/styles.css';

@import './normalize';
@import './reset';
@import './fonts';
@import './typography';
@import './colors';
@import './constants';
@import './mixins';


html {
  font-size: 10px;

  @media (max-width: 1200px) {
    font-size: 8px;
  }

  @media (max-width: 1080px) {
    font-size: 7px;
  }
}

* {
  font-family: Sarabun, sans-serif;
  scroll-behavior: smooth;
}

.container {
  max-width: calc(160rem + 16px * 2);

  @media (max-width: 1440px) {
    max-width: calc(132rem + 16px * 2);
  }

  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
}


.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.nowrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.Notification {
  box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.25);
  border-radius: .4rem;
  overflow: unset;
  padding: 2rem 1.6rem;

  &:hover .ant-notification-notice-close {
    opacity: 1;
  }

  .ant-notification-notice-with-icon {
    display: flex;
  }

  .ant-notification-notice-message {
    display: none;
  }

  .ant-notification-notice-close {
    width: 2.4rem;
    height: 2.4rem;
    background: $white;
    border-radius: 50%;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    box-shadow: 0px 0px 16px rgba(38, 50, 56, 0.08);
    transition: $common-transition;
    opacity: 0;
  }

  .ant-notification-notice-description {
    font-size: 1.6rem;
    color: $grayscale-900;
    line-height: 2.8rem;
    margin-left: 1.2rem !important;
    margin-top: 0.2rem;
  }

  .ant-notification-notice-icon {
    position: relative;
    display: block;
    max-width: 3.2rem;
    flex: 0 0 3.2rem;
    width: 3.2rem;
    height: 3.2rem;
  }

  &.ant-notification-notice-success {
    background: $lime-100;
  }

  &.ant-notification-notice-error {
    background: $red-100;
  }
}

.ant-form {
  &-item {
    &-label {

      &>label {
        flex-direction: row-reverse;
        justify-content: flex-end;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2.4rem;
        color: $grayscale-600;
        column-gap: 2px;

        &::before {
          margin: 0;
          color: $red-500 !important;
          font-size: 1.4rem !important;
          font-weight: 600;
        }
      }
    }
  }
}

a {
  color: $grayscale-900;

  &:hover {
    color: $blue-500;
  }
}


.animation-submit {
  .animation-submit-main {
    transition: $common-transition;
    background: $white;
  }

  .animation-submit-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    transition: $common-transition;
    transform: translateY(100%);

    .Button {
      min-width: 7rem;
    }
  }


  &.show {
    .animation-submit-main {
      padding-bottom: 9.6rem;
    }

    .animation-submit-actions {
      transform: translateY(0%);
    }
  }
}

.ant-dropdown {
  z-index: 1049;
}

.ant-notification {
  z-index: 1052;
}

.ant-select-dropdown {
  z-index: 1049;
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1051;
}

.ant-drawer {
  z-index: 1050;
}

.Zoom {
  [data-rmiz-modal-overlay=visible] {
    background: rgba($black, 0.6);
    backdrop-filter: blur(5px);
  }

  img {
    user-select: none;
  }

  button {
    display: none;
  }
}

.task-done {
  color: $grayscale-200 !important;
  text-decoration: line-through;
}
