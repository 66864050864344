@import '../../assets/styles/global';

.Avatar {
  position: relative;

  .ant-avatar-square {
    border-radius: 0;
  }

  &.show-zoom {
    &.circle {
      &::before {
        border-radius: 50%;
      }
    }

    &::before {
      @include fullAbsolute;
      pointer-events: none;
      content: '';
      z-index: 2;
      border: 1px solid $blue-500;
      transition: $common-transition;
      opacity: 0;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  .ant-avatar {
    width: 4rem;
    height: 4rem;
    background: $grayscale-50;
  }

  &-status {
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    cursor: pointer;

    &.online {
      background: $lime-500;
      border-color: $lime-200;
    }

    &.offline {
      background: $white;
      border-color: $grayscale-200;
    }
  }
}
