@import '@/assets/styles/global';

.Select {
  &.show-only-text {
    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      cursor: text;
      background: transparent !important;
    }
  }

  &.read-only {
    pointer-events: none;
    user-select: none;
  }

  &.SOFT_BLUE {
    .ant-select-selector {
      border-color: $blue-100 !important;
      background: $blue-100 !important;

      .ant-select-selection-item {
        color: $blue-600 !important;
      }
    }
  }

  &.SOFT_GREEN {
    .ant-select-selector {
      border-color: $lime-100 !important;
      background: $lime-100 !important;

      .ant-select-selection-item {
        color: $lime-800 !important;
      }
    }
  }

  &.SOFT_RED {
    .ant-select-selector {
      border-color: $red-100 !important;
      background: $red-100 !important;

      .ant-select-selection-item {
        color: $red-700 !important;
      }
    }
  }

  &.SOFT_PURPLE {
    .ant-select-selector {
      border-color: $remy !important;
      background: $remy !important;

      .ant-select-selection-item {
        color: $medium-red-violet !important;
      }
    }
  }

  &.SOFT_VIOLET {
    .ant-select-selector {
      border-color: $violet-100 !important;
      background: $violet-100 !important;

      .ant-select-selection-item {
        color: $violet-700 !important;
      }
    }
  }

  &.SOFT_GRAY {
    .ant-select-selector {
      border-color: $grayscale-50 !important;
      background: $grayscale-50 !important;

      .ant-select-selection-item {
        color: $grayscale-600 !important;
      }
    }
  }

  .ant-select {
    width: 100%;

    .ant-select-arrow {
      .Icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-search {
      left: 1.2rem;
    }

    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: $grayscale-900;
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: $grayscale-900;
    }

    &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      transform: translateY(20%);
      line-height: 1 !important;
    }

    &:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: .4rem;
      padding: .8rem 1.2rem;
      height: 4.6rem;
      border-color: $grayscale-200;
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: $grayscale-900;

      .ant-select-selection-placeholder {
        color: $grayscale-400;
        line-height: 1 !important;
        transform: translateY(20%);
      }
    }

    &.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
      padding: .4rem 1.2rem;
      height: 3.8rem;
      font-size: 1.4rem;
    }

    &.ant-select-show-search:not(.ant-select-customize-input) .ant-select-selector input {
      height: 100%;
    }
  }

  &-dropdown {
    border: 1px solid $grayscale-50;
    box-shadow: 0 2px 4px rgba(38, 50, 56, 0.15);
    border-radius: 4px !important;
    overflow: hidden;
    width: unset !important;

    &-main {
      &-add-option {
        padding: .4rem 1.2rem;
      }
    }

    .ant-select-item {
      position: relative;
      padding: .8rem 1.2rem;
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: $grayscale-900;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background: lighten($blue-100, 2%);
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background: $blue-100;
    }
  }

  &-user-custom {
    padding: .8rem 1.2rem;
    column-gap: 1.2rem;
    cursor: pointer;
    transition: $common-transition;

    &:hover,
    &.active {
      background: $blue-100;
    }

    &-info {
      &-title {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: $grayscale-900;
      }

      &-description {
        font-size: 1.2rem;
        line-height: 2rem;
        color: $grayscale-600;
      }
    }
  }

  .ant-select-clear {
    right: calc(2rem);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  &-clear-icon {
    background: $white;
    width: 2.4rem;
    height: 2.4rem;
  }
}
