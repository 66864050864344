@import '@/assets/styles/_global.scss';

.Empty {
  .ant-empty-image {
    margin-bottom: 0;
  }

  .ant-empty-image {
    height: fit-content;
    svg {
      width: 8rem !important;
      height: 8rem !important;
    }
  }

  &-description {
    font-size: 1.6rem;
    line-height: 2.8rem;
    text-align: center;
    color: $grayscale-400;
  }

  &-btn {
    margin-top: 1.6rem;
  }
}