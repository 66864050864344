@import '@/assets/styles/_global.scss';

.ModalImportEmployee {
  &-text {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: $grayscale-900;

    span {
      color: $blue-500;
      font-weight: 600;
      cursor: pointer;
      transition: $common-transition;

      &:hover {
        color: $blue-700;
        text-decoration: underline;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }

  &-wrapper {
    .ant-form-item {
      margin-bottom: 0;
    }

    .UploadDragDrop {
      margin-bottom: 3.2rem;
    }
  }
}
