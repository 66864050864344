@import '@/assets/styles/_global.scss';

.Status {
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  border-radius: 0.4rem;
  width: fit-content;

  &.have-action {
    cursor: pointer;
    transition: $common-transition;
    border: 1px solid transparent;
  }

  &.small {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 0 0.4rem;
  }

  &.SOFT_BLUE {
    background: $blue-100 !important;
    color: $blue-600 !important;
    border-color: $blue-100 !important;

    &:hover {
      border-color: $blue-600 !important;
    }
  }

  &.SOFT_GREEN {
    background: $lime-100 !important;
    color: $lime-800 !important;
    border-color: $lime-100 !important;

    &:hover {
      border-color: $lime-800 !important;
    }
  }

  &.SOFT_RED {
    background: $red-100 !important;
    color: $red-700 !important;
    border-color: $red-100 !important;

    &:hover {
      border-color: $red-700 !important;
    }
  }

  &.SOFT_PURPLE {
    background: $remy !important;
    color: $medium-red-violet !important;
    border-color: $remy !important;

    &:hover {
      border-color: $medium-red-violet !important;
    }
  }

  &.SOFT_VIOLET {
    background: $violet-100 !important;
    color: $violet-700 !important;
    border-color: $violet-100 !important;

    &:hover {
      border-color: $violet-700 !important;
    }
  }

  &.SOFT_GRAY {
    background: $grayscale-50 !important;
    color: $grayscale-600 !important;
    border-color: $grayscale-50 !important;

    &:hover {
      border-color: $grayscale-600 !important;
    }
  }

  &.SOFT_YELLOW {
    background: $yellow-100 !important;
    color: $yellow-700 !important;
    border-color: $yellow-100 !important;

    &:hover {
      border-color: $yellow-700 !important;
    }
  }
}
