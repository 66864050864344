@import '@/assets/styles/_global.scss';

.Header {
  position: fixed;
  z-index: 1049;
  top: 0;
  left: 0;
  width: 100%;
  background: $blue-500;
  padding: 0 1.6rem;

  &.have-background {
    background: $black-25;
    backdrop-filter: blur(5px);
  }

  &-wrapper {
    height: 7.2rem;
    column-gap: 3.2rem;
  }

  &-logo {
    position: relative;
    max-width: 12rem;
    max-height: 5rem;
    @include centerOriginImage;

    a {
      display: flex;
    }
  }

  &-nav {
    column-gap: 3.2rem;

    &-item {
      position: relative;
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: $white;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }

      &:hover,
      &.active {
        color: $white;

        &::before {
          transform: translate(-50%, 0) scaleX(1);
        }
      }

      &::before {
        position: absolute;
        content: '';
        top: calc(100% + .4rem);
        left: 50%;
        transform: translate(-50%, 0) scaleX(0);
        width: 2.4rem;
        height: .4rem;
        background: $white;
        transition: $common-transition;
      }
    }
  }

  &-search {
    margin-left: auto;
    flex: 1;
    max-width: 25rem;
  }

  &-actions {
    column-gap: .4rem;
    margin-left: auto;

    &-item {
      position: relative;

      &-badge {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 2rem;
        text-align: center;
        z-index: 1;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 2rem;
        color: $white;
        padding: 0 .4rem;
        border-radius: .8rem;
        background: $red-500;
        pointer-events: none;
      }
    }
  }
}
