@import '@/assets/styles/global';

.DropdownCustom {
  &-wrapper {
    cursor: pointer;
  }

  &-overlay {
    background: $white;
    border: 1px solid $grayscale-50;
    box-shadow: 0 2px 4px rgba(38, 50, 56, 0.15);
    border-radius: 4px;
    overflow: hidden;
  }
}