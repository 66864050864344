@import '@/assets/styles/global';

.TextArea {
  position: relative;

  &.preview {
    .ant-input {
      min-height: unset;
      padding: 0;
      border: none;
      resize: none;
      box-shadow: none;
      cursor: default;
    }
  }

  .ant-input {
    min-height: 15.8rem;
    padding: .8rem 1.2rem;
    font-size: 1.6rem;
    line-height: 2.8rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: $grayscale-900;
    background: $white;
    border-color: $grayscale-200;
    border-radius: .4rem;

    &:disabled {
      border-color: $grayscale-100;
      background: $grayscale-50;
      color: $grayscale-600;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      border-color: $grayscale-900;
    }

    &::placeholder {
      color: $grayscale-400;
    }
  }

  .TextAreaEmoji {
    position: absolute;
    bottom: .8rem;
    right: .8rem;
  }

  .ant-input-textarea-show-count::after {
    position: absolute;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $grayscale-500;
    bottom: 1rem;
    margin-bottom: 0 !important;
    right: calc(2rem + .8rem * 2);
  }
}
