@import '@/assets/styles/_global.scss';

.Admin {
  &::before {
    @include fullAbsolute;
    position: fixed;
    z-index: 2;
    border: 0 solid rgba($black, 0.4);
    content: '';
    transition: 0.5s ease;
  }

  &-header .Header,
  &-body {
    transition: 0.5s ease;
  }

  &-protect-screen-btn {
    opacity: 0;
    position: fixed;
    width: 1.6rem;
    height: 1.6rem;
    z-index: 1051;
    background: red;
    bottom: 0;
    left: 0;
  }

  &.protect-screen {
    &::before {
      border-width: 1rem;
    }

    .Admin {
      &-header .Header {
        transform: translateY(-100%);
      }

      &-body {
        transform: translate(-100%, 100%);
      }
    }
  }

  &-body {
    position: relative;
    z-index: 2;
    padding-top: 7.2rem;
  }

  &-background {
    position: fixed;
    pointer-events: none;
    background: $mercury;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include centerScaleImage;
  }
}
