@mixin ellipsis($line) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin fullAbsolute {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerScaleImage {
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin: auto;
  }
}

@mixin centerOriginImage {
  display: flex;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
    margin: auto;
  }
}

@mixin styleScrollBar() {
  &::-webkit-scrollbar-track {
    height: .8rem;
  }

  &::-webkit-scrollbar {
    width: .8rem;
    height: .8rem;
    background: rgba(188, 188, 188, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(188, 188, 188, 0.2);
    cursor: pointer;
    transition: $common-transition;

    &:hover {
      background: rgba(188, 188, 188, 0.6);
    }
  }
}

@mixin resetStyleCollapse($paddingTopContent) {
  .ant-collapse {
    border: none;
    background: none;

    &>.ant-collapse-item {
      border: none;
    }

    .ant-collapse-header {
      padding: 0 !important;
    }

    .ant-collapse-content {
      padding-top: $paddingTopContent;
      border: none;
      background: none;

      &>.ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

@mixin styleStatusCircle {
  width: 1.6rem;
  flex: 0 0 1.6rem;
  max-width: 1.6rem;
  height: .6rem;
  border-radius: .4rem;
  border: 1px solid;
}
